import * as Styled from "../styled";
import LeftBorderCard from "@/components/shared-ui/LeftBorderCard";
import UpdatesCard from "../../UpdatesCard";
import Illustration from "../../../../assets/dashboard-illustration.svg";

type Props = {
  companyName: string;
  isFullUser: boolean;
  firmID: string;
};

const WelcomeCard = ({ companyName, isFullUser, firmID }: Props) => {
  const showAllFeatures = isFullUser && !!firmID;

  return (
    <Styled.WelcomeCardOuter>
      <Styled.WelcomeCardInner>
        <div>
          <h1 data-testid="companyName">
            {companyName} & Seccl:
            <Styled.AccentHeading>your customer hub</Styled.AccentHeading>
          </h1>
        </div>
        <Styled.WelcomeCardImageSection>
          <strong>
            Welcome to your customer hub – home to the key resources you’ll need
            to launch, operate and scale your platform on Seccl.
          </strong>
          <img src={Illustration} alt="" />
        </Styled.WelcomeCardImageSection>
      </Styled.WelcomeCardInner>

      <Styled.UpdatesCard>
        <Styled.CardHeading>Important updates</Styled.CardHeading>
        <UpdatesCard />
      </Styled.UpdatesCard>

      {showAllFeatures && (
        <LeftBorderCard
          heading="Platform setup"
          content="Find details of your platform configuration and key
                  proposition details"
          internalLink="platform-management"
          buttonLabel="Find out more"
          gridColumn="1"
        />
      )}

      <LeftBorderCard
        heading="Product roadmap"
        content="Find the latest updates on upcoming products"
        internalLink="product-roadmap"
        buttonLabel="Find out more"
        gridColumn={showAllFeatures ? "2" : ""}
      />
    </Styled.WelcomeCardOuter>
  );
};

export default WelcomeCard;
