import { Link } from "react-router-dom";
import Header from "../Header";
import * as Styled from "./styled";
import { ReactComponent as Arrow } from "@assets/icons/arrow-left.svg";
import type { SearchIndexTypes } from "@/components/shared-ui/Search/Search";
import Footer from "../Footer";

type Props = {
  children: React.ReactNode;
  title: string;
  fullWidth?: boolean;
  noPadding?: boolean;
  stickyBreadcrumbs?: boolean;
  isDashboard?: boolean;
  isAdmin?: boolean;
  whiteBackground?: boolean;
  customBreadcrumb?: { path: string; label: string };
  searchIndexPriority?: SearchIndexTypes;
};

const Page = ({
  children,
  fullWidth,
  noPadding,
  stickyBreadcrumbs,
  isDashboard,
  isAdmin,
  whiteBackground,
  customBreadcrumb,
  searchIndexPriority,
}: Props) => {
  const renderBreadcrumbs = () => {
    if (customBreadcrumb) {
      return (
        <Link to={customBreadcrumb.path}>
          <Arrow /> {customBreadcrumb.label}
        </Link>
      );
    }
    if (isAdmin) {
      if (isDashboard) {
        return (
          <Link to="/">
            <Arrow /> Back to customer hub
          </Link>
        );
      } else {
        return (
          <Link to="/admin">
            <Arrow /> Back to admin dashboard
          </Link>
        );
      }
    }
    if (isDashboard) {
      return (
        <a href="https://seccl.tech" target="_blank" rel="noreferrer">
          Visit Seccl website
        </a>
      );
    } else {
      return (
        <Link to="/" data-testid="backToDashBoard">
          <Arrow /> Back to dashboard
        </Link>
      );
    }
  };

  return (
    <Styled.PageBackground
      $featureBackground={isDashboard}
      $stickyBreadcrumbs={stickyBreadcrumbs}
      $whiteBackground={whiteBackground}
      $isAdmin={isAdmin}
    >
      <Header isAdmin={isAdmin} searchIndexPriority={searchIndexPriority} />
      <Styled.Breadcrumbs $stickyBreadcrumbs={stickyBreadcrumbs}>
        <Styled.BreadcrumbsContent>
          {renderBreadcrumbs()}
        </Styled.BreadcrumbsContent>
      </Styled.Breadcrumbs>
      <Styled.Body $fullWidth={fullWidth} $noPadding={noPadding}>
        {children}
      </Styled.Body>
      {!isAdmin && <Footer />}
    </Styled.PageBackground>
  );
};

export default Page;
