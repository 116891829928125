import LeftBorderCard from "@/components/shared-ui/LeftBorderCard";
import * as Styled from "./styled";
import AmysPhoto from "@assets/amys-review.jpeg";

const CardContent = () => {
  return (
    <Styled.AmysReviewCard>
      <p>
        A short commentary from Amy Carmichael (our Head of product) every month
        to explain roadmap movement, delivery and changes.
      </p>
    </Styled.AmysReviewCard>
  );
};

const AmysReviewCard = ({ updateLink }: { updateLink: string }) => {
  return (
    <LeftBorderCard
      image={AmysPhoto}
      heading="Roadmap review"
      content={<CardContent />}
      internalLink={updateLink}
      buttonLabel="Read Amy's update"
    />
  );
};

export default AmysReviewCard;
