import styled from "styled-components";

export const Footer = styled.footer`
  width: 100%;
  margin-top: 2rem;
  background: ${(props) => props.theme.gradients.dark1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem 2rem;
  color: ${(props) => props.theme.colors.lightest};
`;

export const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2rem 1rem;
  margin: 0 auto;
  width: calc(100% - 2rem);
  max-width: 1200px;
`;

export const FooterLogo = styled.div`
  grid-column: 1 / -1;

  ${(props) => props.theme.media.medium} {
    grid-column: 1 / 3;
  }

  svg {
    height: 3rem;
    width: 6rem;
  }
`;

export const FooterLinks = styled.div`
  grid-column: 1 / -1;
  display: flex;
  gap: 2rem;
  flex-direction: column;

  ${(props) => props.theme.media.medium} {
    grid-column: 8 / -1;
    flex-direction: row;
    justify-self: end;
  }
`;

export const FooterNav = styled.div`
  h5 {
    margin: 0 0 0.5rem;
    display: block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }

  ul {
    margin: 0;
    padding: 0;
    visibility: visible;
  }

  li {
    display: block;
    margin-top: 0.5rem;
    cursor: default;
    padding-left: 0;
    font-size: 0.6875rem;
    line-height: 0.9375rem;
    font-weight: 300;
  }
`;

export const FooterSocials = styled.div`
  display: flex;
  gap: 0.5rem;

  a {
    height: 1.56rem;
    width: 1.56rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #f8f4fd 0%, #fee7ed 100%);
    padding: 0.4rem;
    line-height: 0;
    border-radius: 100%;
    color: #1d1739;
  }
`;

export const FooterSmallPrint = styled.div`
  display: grid;
  grid-gap: 1rem 1rem;
  grid-column: 1 / -1;
  grid-template-columns: repeat(4, 1fr);
  align-items: start;
  border-top: 1px solid #3f2962;
  padding-top: 2rem;

  ${(props) => props.theme.media.medium} {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const BCorpLogoWrapper = styled.div`
  grid-column: 1 / 3;

  ${(props) => props.theme.media.medium} {
    grid-column: 1 / 3;
  }

  max-width: 8rem;
  color: ${(props) => props.theme.colors.lightest};

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const ISOLogoWrapper = styled.div`
  grid-column: 3 / -1;

  ${(props) => props.theme.media.medium} {
    grid-column: 3 / 5;
  }

  img {
    width: 100%;
    max-width: 8rem;
  }
`;

export const FooterColophon = styled.div`
  grid-column: 1 / -1;

  ${(props) => props.theme.media.medium} {
    grid-column: 5 / -1;
  }

  font-size: 0.6875rem;
  line-height: 0.9375rem;
  font-weight: 300;
`;
