import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import DocLayout from "@/components/layout/DocLayout";
import useApi from "@/contexts/ApiContext";
import { Link, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import BlockRenderer from "@/components/shared-ui/BlockRenderer";
import { Download } from "@/components/layout/DocLayout/styled";
import FileDownloadButton from "@/components/utility/FileDownloadButton";
import { Badge } from "../Dashboard/UpdatesCard/styled";
import useDocsActiveHeading from "@/contexts/DocsActiveHeadingContext";
import type { DirectusBlockText } from "@/services/api/types";
import { AnchorItem } from "../Documentation/styled";

const Update = () => {
  const api = useApi();
  const { updateId } = useParams();
  const { activeId } = useDocsActiveHeading();
  const location = useLocation();
  const { prevLocation } = location.state || {};
  const { data: update } = useQuery(["get-update", updateId], () =>
    api.update.get(updateId || "")
  );

  const { data: updates } = useQuery(["get-updates"], () =>
    api.update.getAll(3)
  );

  if (!update || !updates) {
    return <LoadingScreen />;
  }

  // Get all text blocks
  const textBlocks = update.content.filter(
    (block): block is DirectusBlockText => block.collection === "text_block"
  );
  // Get an array of all level 3 headings
  const levelThreeHeadings = textBlocks?.reduce((accumulator, currentValue) => {
    const h3Blocks = currentValue.item.text_content.blocks.filter(
      (item) => item.type === "header" && item.data.level === 3
    );
    h3Blocks.forEach((block) => accumulator.push(block.data.text));
    return accumulator;
  }, [] as string[]);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  };

  const sidebar = (
    <>
      {levelThreeHeadings.length > 0 && (
        <Styled.AnchorHeading>On this page</Styled.AnchorHeading>
      )}
      <div>
        {levelThreeHeadings?.map((heading) => {
          const anchorSuitableId = heading.replace(/\s+/g, "-").toLowerCase();
          return (
            <AnchorItem
              key={anchorSuitableId}
              href={`#${anchorSuitableId}`}
              $active={activeId === anchorSuitableId}
            >
              {heading}
            </AnchorItem>
          );
        })}
      </div>

      {update.download && (
        <>
          <Styled.AnchorHeading>Download</Styled.AnchorHeading>
          <Download>
            <FileDownloadButton
              fileObject={update.download}
              makeParentClickable
            />
            {update.download.title}
          </Download>
        </>
      )}
      <Styled.AnchorHeading>Recent notes</Styled.AnchorHeading>
      <div>
        {updates.map((item) => (
          <Link to={`/updates/${item.id}`} key={item.id}>
            <Styled.RecentUpdatesItem $category={item.category}>
              <span>{item.title}</span>
              <Styled.Date>{formatDate(item.date)}</Styled.Date>
            </Styled.RecentUpdatesItem>
          </Link>
        ))}
      </div>
    </>
  );

  const docPrefix = (
    <Styled.RecentUpdatesItem $category={update.category}>
      <Badge>{update.category}</Badge>
      <Styled.Date>{formatDate(update.date)}</Styled.Date>
    </Styled.RecentUpdatesItem>
  );

  const breadcrumb = prevLocation
    ? { label: "Back to roadmap", path: prevLocation }
    : { label: "Back to updates", path: "/updates" };

  return (
    <Page
      title="Update"
      customBreadcrumb={breadcrumb}
      noPadding
      fullWidth
      stickyBreadcrumbs
      searchIndexPriority="updates"
      whiteBackground
    >
      <DocLayout
        primarySidebar={{
          visible: false,
          heading: "",
          menu: <></>,
          accordionHeading: "",
        }}
        secondarySidebar={{
          visible: true,
          menu: sidebar,
        }}
        docPrefix={docPrefix}
        docHeading={update.title}
        docContent={<BlockRenderer blocks={update.content} />}
      />
    </Page>
  );
};

export default Update;
